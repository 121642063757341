import { computed, ref, watch } from '@vue/composition-api'
import store from '@/store'
import useProduct from '@/views/product-hotel/useProduct'
import util from '@/global/util'
import ProductModal from '@/modal/productModal'
import constant from '@/global/const'

export default function useProductCreate() {
  const {
    productMenuType,
    productMenuTypeVI,
    renderProductTitle,
    renderProductContent,
    //
    checkProductUnique,
    createProduct,
    fetchProduct,
    updateProduct,
  } = useProduct()

  const duplicateError = ref(null)
  const formBaseStatus = ref([])
  const product = ProductModal()

  const routeName = computed(() => store.state.app.currentRouteName)
  const districts = computed(() => store.state.app.districts)
  const wards = computed(() => store.state.app.wards)
  const streets = computed(() => store.state.app.streets)

  watch(() => product.province_id, n => {
    if (!n) return
    store.dispatch('app/fetchDistricts', { province_id: n })
  })
  watch(() => product.district_id, n => {
    if (!n || !product.province_id) return
    store.dispatch('app/fetchWards', { province_id: product.province_id, district_id: n })
    store.dispatch('app/fetchStreets', { province_id: product.province_id, district_id: n })
  })
  watch([() => product.width, () => product.length, () => product.floor_number], n => {
    const w = parseFloat(n[0] || 0)
    const l = parseFloat(n[1] || 0)
    const f = parseFloat(n[2] || 0)
    if (!w || !l) return
    product.acreage = w * l * (f + 1)
  })
  watch([() => product.acreage, () => product.input_price, () => product.currency, () => product.type_of_payment], ([a, p, c, t]) => {
    const val = t === 'm2' ? a * p : p
    product.price = val
    if (c.toLowerCase() === 'million') {
      product.price = val * 1000000
    }
    if (c.toLowerCase() === 'billion') {
      product.price = val * 1000000000
    }
    product.priceDisplay = `${util.formatNumber(val || 0)} ${constant.CURRENCY.find(item => item.value === c)?.text?.toUpperCase()}`
  })
  watch(() => product.transfer_price, n => {
    product.transferPriceDisplay = `${util.formatNumber(n || 0)} ${constant.CURRENCY.find(item => item.value === (product.transfer_price_currency || 'usd'))?.text?.toUpperCase()}`
  })

  return {
    duplicateError,
    formBaseStatus,
    productMenuType,
    productMenuTypeVI,
    routeName,
    districts,
    wards,
    streets,
    product,
    renderProductTitle,
    renderProductContent,
    //
    checkProductUnique,
    fetchProduct,
    createProduct,
    updateProduct,
  }
}
