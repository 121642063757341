<template>
  <div>
    <form action="">
      <b-card
        id="product-form-detail"
        ref="product-form-detail"
        no-body
        class="custom-border-top-1px"
      >
        <div class="card-header">
          <b-badge
            variant="light-primary"
            class="font-small-4 px-1 py-50"
          >
            THÔNG TIN CHI TIẾT
          </b-badge>
          <div class="d-flex flex-column">
            <b-button
              :variant=" renderProductStatus === undefined ? 'success' : 'primary'"
              size="sm"
              @click="renderProductData"
            >
              <feather-icon icon="ArrowDownCircleIcon" />
              Lấy dữ liệu
            </b-button>
          </div>
        </div>
        <div class="card-body">
          <b-row>
            <b-col cols="12">
              <!--              Tiêu đề-->
              <dynamic-form
                :id="'product-form-tieude'"
                :value="value.title"
                :type="'text'"
                :label="'Tiêu đề'"
                placeholder="Tiêu đề mô tả sản phẩm"
                required="required"
                @update-value="val => value.title = val"
              />
            </b-col>

            <b-col cols="12">
              <!--              Mô tả sản phẩm-->
              <label>Mô tả sản phẩm</label>
              <quill-editor
                id="product-form-editor-quill"
                ref="product-form-editor-quill"
                v-model="value.content"
                :options="{ theme: 'snow' }"
                style="min-height:200px"
              />
              <br>
            </b-col>

          </b-row>
        </div>
      </b-card>
    </form>
  </div>
</template>

<script>
import {
  BBadge, BCard, BCol, BRow, BButton,
} from 'bootstrap-vue'
import { ref, watch } from '@vue/composition-api'
import DynamicForm from '@core/dynamic-form/DynamicForm.vue'
import { quillEditor } from 'vue-quill-editor'
import useProductCreate from '@/views/product-hotel/create/useProductCreate'

export default {
  components: {
    quillEditor,
    BBadge,
    BCard,
    BCol,
    BRow,
    BButton,
    DynamicForm,
  },
  props: {
    value: {
      type: Object,
      require: true,
      default: () => {},
    },
  },
  methods: {
    updateByCheckboxGroup(options, checkedList) {
      if (!options.length) return
      options.forEach(item => {
        this.value[item.value] = checkedList.includes(item.value)
      })
    },
    renderProductData() {
      this.value.title = this.renderProductTitle(this.value)
      this.value.content = this.renderProductContent(this.value)
      setTimeout(() => {
        this.renderProductStatus = ''
        this.$emit('update-form-status', this.renderProductStatus)
      }, 100)
    },
  },
  setup(props, { emit }) {
    const {
      product, renderProductTitle, renderProductContent,
    } = useProductCreate()

    const renderProductStatus = ref(undefined) // [undefined, '', error]

    watch(product, () => {
      renderProductStatus.value = undefined
      emit('update-form-status', renderProductStatus.value)
    })

    return {
      product,
      renderProductStatus,
      renderProductTitle,
      renderProductContent,
    }
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/quill.scss';
</style>
